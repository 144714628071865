/* 




.display {
  display: flex;
  margin-top: 10px;
}



@media all and (max-width: 768px) {
  .leftSidebar {
    position: static;
    width: 100%;
    height: auto;
    border-right: 0;
    border-bottom: 1px solid #f0f0f0;
  }
}

.author .author-list {
  max-height: 750px;
  height: 750px;
  
}



.result-data .result-image {
  background-size: cover;
  max-height: 200px;
}
*/

.suggestionlist {
  color: #424242;
  width: 90%;
  margin-left: 5%;
  margin-top: 10%;
}

.datasearch {
  margin-left: auto;
  margin-right: auto;
}

.datasearch .searchbox {
  min-width: 400px;
  width: 700px;
  height: 70px;
  font-size: 24px;
  border-radius: 30px;
  padding: 5px 16px 5px 30px;
}

.mainBar {
  width: calc(100% - 140px);
  position: relative;
  left: 10px;
  padding: 0px 30px;
  background-color: #fefefe;
}

.result-author {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.result-item {
  min-height: 500px;
  height: 730px;
  background-color: #212121 !important;
  margin-right: 17px !important;
  margin-bottom: 17px !important;
  padding: 0px !important;
}

.result-image {
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  overflow: hidden;
  height: 325px !important;
  width: 250px !important;
  margin-bottom: 7px;
} 
.book-title {
  color: black;
  font-family: 'Roboto', sans-serif;
  text-align: middle;
  vertical-align: top;
  
}

.header-span {
  padding-left: 500px;
  padding-block-start: 100px;
  
}

.leftSidebar {
  width: 1000px;
  height: 100%;
  padding: 10px 20px;
  left: 0;
  right: 0;
  border-right: 1px solid #f0f0f0;
  background-color: #f0f0f0;
}

.leftSidebar > div {
  margin: 40px 0;
  background-color: #f0f0f0;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.color-nav
{
  background-color: white;
}


.display {
  display: flex;
  margin-top: 10px;
  justify-content: left;
}

.leftSidebar {
  width: 320px;
  height: 100%;
  padding: 15px 20px;
  left: 0;
  right: 0;
  border-right: 1px solid #f0f0f0;
  background-color: #ffffff;
}

.leftSidebar > div {
  margin: 40px 0;
  background-color: #ffffff;
}

.defaultTextBox {
  padding: 0;
  height: 40px;
  width: 540px;
  outline: none;
  border: 1px solid #cdcdcd;
  border-color: rgba(0,0,0,.15);
  background-color: white;
  font-size: 16px;
  position: relative;
    margin: auto;
}

.backgroundcolor1 {
  height: 120px;
  text-align: center;
  align-content:center;
  vertical-align:middle;
  
  
}

.oPaqueSignIn {
  opacity: 0.5042085;
  z-index: 3;
  width: 24px;
  height: 24px;
}

.headerText {
  z-index: 3;
  width: 42.28px;
  height: 17px;
  color: rgba(107,112,117,0.75);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

.signInText {
  z-index: 3;
  width: 42.28px;
  height: 18px;
  color: rgba(107,112,117,0.75);
  font-family: Roboto;
  font-size: 17px;
  font-weight: 600;
  line-height: 17px;
}

.darkBackground {
  background: #414241;
  height: 32px;
  line-height: 32px;
  font-family: Roboto;
  text-align: center;
  vertical-align: midddle;
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0.8888889px;  
}

.whiteTextMiddle
{
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: normal;
  
}

.frontPageHeaders
{
    z-index: 3;
    font-family: Roboto;
    font-size: 36px;
    font-weight: 400;
    line-height: 43px;
    text-align: center;
  
}

.SubHeaders
{
  font-family: Roboto;
  font-size: 36px;
  font-weight: 400;
  line-height: 43px;
  text-align: center;
}

.contentHeaders
{
  font-family: Roboto;
  font-size: 36px;
  font-weight: 400;
  line-height: 48px;
  text-align: center;
}

.contentSubHeaders
{
  font-family: Roboto;
  font-size: 36px;
  font-weight: 400;
  line-height: 48px;
  text-align: center;
}

.contentLowHeaders
{
  font-family: Roboto;
  font-size: 30px;
  font-weight: 400;
  line-height: 48px;
  text-align: left;
  align-items: left;
}

.whoShouldBuy
{
  opacity: 0.07145182;
  z-index: 3;
  height: 530px;
  background: #f7b500;
  text-align: center;
  align-items: center;
  align-content: center;
  vertical-align: middle;
}

.subHeader2
{
  z-index: 3;
  font-family: Roboto;
  font-size: 34px;
  font-weight: 500;
  line-height: 43px;
  text-align: center;
  vertical-align: middle;
}

.subText2
{
  z-index: 3;
  color: black;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  text-align: center;
  vertical-align: middle;
}

.subTextWhyBuy
{
  z-index: 3;
  color: black;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  text-align: left;
  vertical-align: middle;
  
  
}

.subTextWhyBuyCenter
{
  z-index: 3;
  color: black;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  text-align:center;
  align-items: center;
  vertical-align: middle;
 
  
}

.appFooterBold
{
  z-index: 4;
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.appFooterBold2
{
  z-index: 4;
  color: black;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  align-items: left;
}

.author-list
{
  font-size: 13px;
  color:#000000;
  font-weight: normal;
}

.appFooterRegular
{
  z-index: 4;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}
.appFooterRegular2
{
  z-index: 4;
  color: black;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  align-items: left;
}

.appFooterSmaller
{
  z-index: 4;
  color: #ffffff;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}

.appFooterSmaller2
{
  z-index: 4;
  color: black;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}

.faq-header
{
  z-index: 4;
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.x-header
{
  z-index: 4;
  color: #000000;
  font-family: Roboto;
  font-size: 72px;
  font-weight: 700;
  text-align: center;
  height: auto; 
  width: auto; 
  max-width: 165px; 
  max-height: 165px;
  
}


.faq-text
{
  z-index: 4;
  color: #000000;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
  line-height: 19px;
  text-align: left;
}

.lower-header
{
  z-index: 4;
  color: #000000;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 300;
  line-height: 19px;
  text-align: left;
}

.lower-text
{
  z-index: 4;
  color: #000000;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 200;
  line-height: 19px;
  text-align: left;
}


.process-header
{
  z-index: 4;
  color: #000000;
  font-family: Roboto;
  font-size: 48px;
  font-weight: 300;
  text-align: middle;
}

.process-text-left
{
  font-family: Roboto;
  font-size: 26px;
  font-weight: 400;
  text-align: left;
  vertical-align: middle;
  
}
.process-text-right
{
 
  z-index: 4;
  font-family: Roboto;
  font-size: 26px;
  font-weight: 400;
  text-align: right;
  vertical-align: middle;
}

.center-text
{
  font-family: Roboto;
  font-size: 24px;
  font-weight: 300;
  text-align: right;
  display: inline-block;
  text-align: left;
  vertical-align:-webkit-baseline-middle;
  line-height: normal;
  border: 2px dashed;
}

.about-small-text
{
  z-index: 4;
  color: #D3D3D3;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 300;
  text-align: right;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

.product-name {
  z-index: 4;
  font-family: Roboto;
  font-size: 300px;
  font-weight: 500;
  line-height: 40px;
}


.by-black {
z-index: 4;
width: 63.45px;
height: 22px;
font-family: Roboto;
font-size: 18px;
font-weight: 400;
line-height: 22px;
}
.by-blue {
  z-index: 3;
  width: 155.55px;
  height: 17px;
  color: #1b72e9;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 17px;
}
.itemAction {
  z-index: 3;
  width: 102.4px;
  height: 22px;
  color: #3c4858;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}
.box-text {
  z-index: 3;
  color: #3f93c7;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
}
.tab-text {
  z-index: 3;
  color: black;
  font-family: Roboto;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
}
.tabs{
  font-family: Roboto;
  margin:0 auto;
  width:90%;
  text-align:center;
  margin-top:2vh;
  vertical-align:top;
}
.tab {
  min-height:50px;
  width:100%;
  padding:25px;
  font-family:'Roboto';
  color:#444;
}
.tab-buttons{
  margin-bottom:50px;
}
#InfoTabs{
  border-radius:3px;
  border: 1px solid #e5e5e5;
  margin:1px;
  background-color:white;
}
button{
  margin-right:15px;
  font-size:20px;
  background:transparent;
  border:none;
  outline:none;
  padding:10px 20px ;
  cursor:pointer;
  color:rgba(0,0,0,.4);
  transition:all ease-in-out .2s;
  border-bottom:2px solid transparent;
  
}
.active{
  border-bottom:2px solid #42b3f4;
 
  color:black;
}
.selectColor {
  z-index: 3;
  width: 97.84px;
  height: 22px;
  color: #3c4858;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}
.aslowas {
  z-index: 4;
  width: 51.45px;
  height: 14px;
  color: black;
  font-family: Roboto;
  font-size: 12pt;
  font-weight: 400;
  line-height: 14px;
}
.couponPrice {
  z-index: 4;
  width: 80.88px;
  height: 29px;
  color: #4caf50;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}
.prodName {
  z-index: 4;
  width: 436px;
  height: 88px;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 700;
  line-height: 29px;
  font-style: bold;
}

.prodSku {
  z-index: 4;
  width: 436px;
  height: 88px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
}

.blueCouponButton {
  z-index: 4;
  width: 144x;
  height: 37px;
  background-color:#0070a3;
	cursor:pointer;
	color:#ffffff;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.225px;
	padding:8px 8px;
  text-decoration:none;
  vertical-align: middle;
  margin-right: 15px;
}
/* .blueCouponButton:hover {
  z-index: 3;
  width: 144px;
  height: 37px;
  background: rgba(78, 77, 77, 0.2);;
} */
.blueCouponButton:active {
	position:relative;
  top:1px;
  width: 144px;
  height: 37px;
  background-color: #0070a3;
}
.savePerItem {
  z-index: 3;
  width: 124px;
  height: 21px;
  color: #6b7075;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}
.selectColorSubText {
  z-index: 3;
  width: 339.42px;
  height: 17px;
  color: #6b7075;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

.green {
  z-index: 4;
  width: 15x;
  height: 15px;
  background-color: green;
	cursor:pointer;
	color:#ffffff;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.225px;
	padding:8px 8px;
  text-decoration:none;
  vertical-align: middle;
  margin-right: 15px;
}

.gray {
  z-index: 4;
  width: 15x;
  height: 15px;
  background-color: gray;
	cursor:pointer;
	color:#ffffff;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.225px;
	padding:8px 8px;
  text-decoration:none;
  vertical-align: middle;
  margin-right: 15px;
}

.tan {
  z-index: 4;
  width: 15x;
  height: 15px;
  background-color: tan;
	cursor:pointer;
	color:#ffffff;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.225px;
	padding:8px 8px;
  text-decoration:none;
  vertical-align: middle;
  margin-right: 15px;
}

.emptyBox {
  z-index: 4;
  width: 15x;
  height: 15px;
	cursor:pointer;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.225px;
	padding:8px 8px;
  text-decoration:none;
  vertical-align: middle;
  margin-right: 15px;
}

.myBox
{
    border: 0px solid #1b72e9;
    border-bottom-width: 1px;
    background-color: transparent;
    width: 293px;
}
.OrderText {
  z-index: 4;
  width: 59.26px;
  height: 19px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}
.ImageSpacing {
  margin-top:5px; /*to have the space above the image*/
   margin-bottom:5px; /*to have the space under the image*/
   margin-left: 4px;
   margin-right: 4px;
}

.preload {
  z-index: 4;
  width: 15x;
  height: 15px;
	cursor:pointer;
	color:#ffffff;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.225px;
	padding:8px 8px;
  text-decoration:none;
  vertical-align: middle;
  margin-right: 15px;
}
.matrixHeader {
  z-index: 5;
  width: 93px;
  height: 18px;
  color: #010101;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}
.matrixItems {
  z-index: 5;
  width: 88px;
  height: 18px;
  color: #6b7075;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

  .modal-main {
    position:fixed;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index: 10;
  }

  .as-low-as
{
  color: #807e7e;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}
.prod-desc
{
  font-family: Roboto;
  font-size: 12pt;
  font-weight: 400;
  line-height: 22px;
}
.prod-price
{
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.prod-totals
{
  font-family: Roboto;
  font-size: 12pt;
  font-weight: 500;
  line-height: 29px;
}
.prod-totals-small
{
  font-family: Roboto;
  font-size: 12pt;
  font-weight: 500;
  font-color: black;
}

.step-one
{
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.blue-box
{
  z-index: 3;
  width: 450px;
  height: 350px;
  border-radius: 8px;
  background: rgba(187,224,247,0.1);
  -moz-box-shadow: inset 0 0 5px 4px rgba(0,0,0,0.5);
-webkit-box-shadow: inset 0 0 5px 4px rgba(0,0,0,0.5);
box-shadow: inset 0 0 5px 4px rgba(0,0,0,0.5);
}
.blue-box-2
{
   z-index: 3;
  width: 450px;
  height: 172px;
  border-radius: 8px;
  background: rgba(251, 251, 251, 0.1);
  -moz-box-shadow: inset 0 0 2px 1px rgba(0,0,0,0.5);
  -webkit-box-shadow: inset 0 0 2px 1px rgba(0,0,0,0.5);
  box-shadow: inset 0 0 2px 1px rgba(0,0,0,0.5);
}

.blue-box-3
{
   z-index: 3;
  width: 120px;
  padding-left: 20px;
  height: 20px;
  border-radius: 8px;
  background: rgba(251, 251, 251, 0.1);
  -moz-box-shadow: inset 0 0 2px 1px rgba(0,0,0,0.5);
  -webkit-box-shadow: inset 0 0 2px 1px rgba(0,0,0,0.5);
  box-shadow: inset 0 0 2px 1px rgba(0,0,0,0.5);
}

.blue-box-4
{
   z-index: 3;
   width: 520px;
  /* width: 120px;
  padding-left: 20px;
  height: 20px; */
  cell-padding: 5px;
  border-radius: 8px;
  background: rgba(251, 251, 251, 0.1);
  -moz-box-shadow: inset 0 0 2px 1px rgba(0,0,0,0.5);
  -webkit-box-shadow: inset 0 0 2px 1px rgba(0,0,0,0.5);
  box-shadow: inset 0 0 2px 1px rgba(0,0,0,0.5);
}

.form-labels
{
  color: #807e7e;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.form-labels-white
{
  color: black;
  background: white;
  font-family: Roboto;
  font-size: 12pt;
  font-weight: 500;
  line-height: 19px;
}
.form-labels-gray
{
  color:  black;
  background: rgb(236, 234, 234);
  font-family: Roboto;
  font-size: 12pt;
  font-weight: 500;
  line-height: 19px;
}
.part-number
{
  height: 24px;
  color: #807e7e;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}
.product-total-price
{
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.unit-quantity
{
  color: #807e7e;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.learn-more-button
{
  z-index: 5;
  width: 150px;
  height: 36px;
  border-radius: 8px;
  background-color: #005788;
  color: white;
  font-family: Roboto;
  font-size: 14pt;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  vertical-align: middle;
  
}

.sign-in-button
{
  z-index: 5;
  width: 90px;
  height: 36px;
  border-radius: 8px;
  background-color: #005788;
  color: white;
  font-family: Roboto;
  font-size: 10pt;
  font-weight: 500;
  line-height: 10px;
  text-align: center;
  vertical-align: middle;
  
}

.inactive-select
{
  z-index: 5;
  width: 400px;
  height: 36px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #FAFAFA;
  color: #000000;
  font-family: Roboto;
  font-size: 16pt;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
  
}

.active-select
{
  fill: #CFD7F7;
  z-index: 4;
  width: 400px;
  height: 36px;
  border-radius: 8px;
  color: #000000;
  border: solid 1px #979797;
  background-color: #CFD7F7;
  font-family: Roboto;
  font-size: 16pt;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.submit-cart
{
  fill: #f6fac2;
  z-index: 4;
  width: 400px;
  height: 36px;
  border-radius: 8px;
  color: #000000;
  border: solid 1px #979797;
  background-color: #fcbb29;
  font-family: Roboto;
  font-size: 16pt;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.lower-header
{
  z-index: 3;
  width: 200.51px;
  height: 29px;
  color: black;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}
.lower-text
{
  z-index: 3;
  width: 470px;
  height: 156px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

.cart-title
{
  z-index: 4;
  width: 514px;
  height: 24px;
  font-family: Roboto;
  color: black;
  font-size: 14pt;
  font-weight: 600;
  line-height: 24px;
  
}
.sku-name
{
  z-index: 4;
  width: 64px;
  height: 24px;
  color: black;
  font-family: Roboto;
  font-size: 12pt;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.35px;
}
.quantity-name
{
  z-index: 4;
  width: 96px;
  height: 24px;
  color: #807e7e;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.totals-format
{
  
  font-family: Roboto;
  font-size: 12pt;
  color: black;
  font-weight: 400;
  line-height: 17px;
}

.xsmall-table
{
  z-index: 3;
  width: 196px;
  border-radius: 4px;
  padding: 4px;
  border-spacing: 4px;
  border-collapse: separate;
  background: rgba(187,224,247,0.1);
  -moz-box-shadow: inset 0 0 3px 2px rgba(0,0,0,0.5);
  -webkit-box-shadow: inset 0 0 3px 2px rgba(0,0,0,0.5);
  box-shadow: inset 0 0 3px 2px rgba(0,0,0,0.5);
}

.big-cart-item
{
  z-index: 3;
  width: 176px;
  height: 24px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: right;
}

.container-qty
{
  z-index: 6;
  width: 76.8px;
  height: 14px;
  color: #3e4144;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.qty-box
{
  z-index: 6;
  color: #3e4144;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}




.cart-icon
{
  z-index: 3;
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

.rounded-corners
{
  z-index: 3;
  width: 280px;
  height: 172px;
  border-radius: 8px;
  text-align: center;
  padding: 10px;
  border-spacing: 10px;
  background: rgba(251, 251, 251, 0.1);
  -moz-box-shadow: inset 0 0 2px 1px rgba(0,0,0,0.5);
  -webkit-box-shadow: inset 0 0 2px 1px rgba(0,0,0,0.5);
  box-shadow: inset 0 0 2px 1px rgba(0,0,0,0.5);
  

}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
   /* Support for all WebKit browsers. */
   -webkit-font-smoothing: antialiased;

   /* Support for Safari and Chrome. */
   text-rendering: optimizeLegibility;

   /* Support for Firefox. */
   -moz-osx-font-smoothing: grayscale;
 
   /* Support for IE. */
   font-feature-settings: 'liga';
}


    /* Rules for using icons as white on a dark background. */
.material-icons.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

table.roundedCorners { 
  border: 2px solid lightgray;
  border-radius: 13px; 
  border-spacing: 0;
  }
table.roundedCorners td, 
table.roundedCorners th { 
  padding: 8px; 
  }
table.roundedCorners tr:last-child > td {
  border-bottom: none;
}
table.smallRoundedCorners { 
  border: 2px solid lightgray;
  border-radius: 13px; 
  border-spacing: 0;
  }
table.smallRoundedCorners td, 
table.smallRoundedCorners th { 
  padding: 2px; 
  }
table.smallRoundedCorners tr:last-child > td {
  border-bottom: none;
}

.cartHeader {
  z-index: 3;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  text-align: left;
  border-bottom: solid 1px #d7d8d6;
}
  .cartSummary {
    z-index: 3;
    width: 115.3px;
    height: 22px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    border-bottom: solid 1px #d7d8d6;
  }
  .cartSummaryTextLeft {
    z-index: 3;
    width: 96px;
    height: 24px;
    color: black;
    font-family: Roboto;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
  .cartSummaryTextRight {
    z-index: 3;
    width: 96px;
    height: 24px;
    color: black;
    font-family: Roboto;
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }

  .cartImage
  {
    height: auto; 
    width: auto; 
    max-width: 165px; 
    max-height: 165px;
  }

  .remove-cart
{
  z-index: 4;
  width: 64px;
  height: 24px;
  color: #807e7e;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.35px;
  text-decoration: underline;
  text-decoration-color: #807e7e;
}

.spacer-text
{
  height:70px;
}

.buy-span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.container-rounded-corners
{
  border-radius: 10px;
  border: 3px;
}
.div.modal div.modal-backdrop {
  z-index: 0;
}
.profileHeader {
  z-index: 3;
  color: #6b7075;
  font-family: Roboto;
  text-align: left;
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
}

.profileText {
  z-index: 3;
  color: #6b7075;
  font-family: Roboto;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
}

.profile-page-header
{
  z-index: 3;
  color: #6b7075;
  font-family: Roboto;
  text-align: left;
  font-size: 36px;
  font-weight: 500;
  line-height: 36px;
}

.this-modal
{
  z-index: 100;
}

.button-underline
{
  padding: 0;
  border: none;
  background: none;
}
.button-underline:hover{
  -fx-underline: true;
}

.cont {
  position: relative;
  width: 100%;
}

/* Make the image responsive */
.cont-img {
  width: 100%;
  height: auto;
}

/* Style the button and place it in the middle of the container/image */
.cont .btn {
  position: absolute;
  top: 75%;
  left: 10%;
  transform: translate(-75%, -25%);
  -ms-transform: translate(-75%, -25%);
  background-color: #555;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.cont .btn:hover {
  background-color: black;
}


.Accordian-Header
{
  z-index: 5;
  height: 25px;
  background-color: #005788;
  color: white;
  font-family: Roboto;
  font-size: 13pt;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  vertical-align: middle;
  
}
.new-footer
{
  background: #f2f4f8;
}